<template>
  <div>
    <b-card no-body>
      <b-overlay
          :show="metricPageViewsLoading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <b-card-body class="mb-2">
        <b-row class="mb-2">
          <b-card-title class="col">
            {{ $t('dashboard.pageViews') }}
          </b-card-title>
          <b-col>
            <b-row>
              <b-col class="d-flex align-items-center justify-content-end">
                <v-select
                    v-model="pageViews.resolutionTime"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="resolutionOptions"
                    @input="initPageViews"
                />
              </b-col>
              <b-col class="d-flex align-items-center justify-content-end">
                <feather-icon
                    icon="CalendarIcon"
                    size="16"
                />
                <flat-pickr
                    v-model="pageViews.rangePicker"
                    :config="{ mode: 'range'}"
                    class="form-control flat-picker bg-transparent border-0 shadow-none"
                    placeholder="YYYY-MM-DD"
                    @on-change="initPageViews"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <chartjs-component-line-chart-wrapper
                v-if="!metricPageViewsLoading && pageViews.datasets.length"
                :height="350"
                :data="pageViews"
                :options="pageViews.options"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div v-if="!metricPageViewsLoading || pageViews.summarization.length">
              <p><strong>TOP 10:</strong></p>
              <div v-for="(val, md5hash) in pageViews.summarization" :key="md5hash">
                <span>{{ val.pageTitle }}: <strong>{{ val.cnt }}</strong></span><br>
              </div>
            </div>
            <div v-else>
              <span><strong>Nem található adat!</strong></span>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      </b-overlay>
    </b-card>
    <b-card no-body>
      <b-overlay
          :show="metricSearchesLoading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <b-card-body class="mb-2">
          <b-row class="mb-2">
            <b-card-title class="col">
              {{ $t('dashboard.searches') }}
            </b-card-title>
            <b-col>
              <b-row>
                <b-col class="d-flex align-items-center justify-content-end">
                  <v-select
                      v-model="searches.resolutionTime"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="resolutionOptions"
                      @input="initSearches"
                  />
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end">
                  <feather-icon
                      icon="CalendarIcon"
                      size="16"
                  />
                  <flat-pickr
                      v-model="searches.rangePicker"
                      :config="{ mode: 'range'}"
                      class="form-control flat-picker bg-transparent border-0 shadow-none"
                      placeholder="YYYY-MM-DD"
                      @on-change="initSearches"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <chartjs-component-line-chart-wrapper
                  v-if="!metricSearchesLoading && searches.datasets.length"
                  :height="350"
                  :data="searches"
                  :options="searches.options"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div v-if="!metricSearchesLoading || searches.summarization.length">
                <p><strong>TOP 10:</strong></p>
                <div v-for="(val, md5hash) in searches.summarization" :key="md5hash">
                  <span>{{ val.queryString }}: <strong>{{ val.cnt }}</strong></span><br>
                </div>
              </div>
              <div v-else>
                <span><strong>Nem található adat!</strong></span>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-overlay>
    </b-card>
    <!--  operating system, agent, platform, referer   -->
    <b-card>
      <b-card-body class="mb-2">
        <b-row class="mb-2">
          <b-card-title class="col">
            {{ $t('dashboard.userStatistics') }}
          </b-card-title>
          <b-col>
            <b-row>
              <b-col class="d-flex align-items-center justify-content-end">
                <feather-icon
                    icon="CalendarIcon"
                    size="16"
                />
                <flat-pickr
                    v-model="metricDistributionData.rangePicker"
                    :config="{ mode: 'range'}"
                    class="form-control flat-picker bg-transparent border-0 shadow-none"
                    placeholder="YYYY-MM-DD"
                    @on-change="initMetricDistributions"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="6">
            <b-card no-body>
              <b-card-header>
                <b-card-title>{{ $t('dashboard.agents') }}</b-card-title>
              </b-card-header>
              <b-card-body>
                <chartjs-component-polar-area-chart-wrapper
                    v-if="!this.metricAgentsLoading"
                    :height="450"
                    :data="metricDistributionData.dataset.agent"
                    :options="polarAreaChartOptions"
                />
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card no-body>
              <b-card-header>
                <b-card-title>{{ $t('dashboard.platforms') }}</b-card-title>
              </b-card-header>
              <b-card-body>
                <chartjs-component-polar-area-chart-wrapper
                    v-if="!this.metricPlatformsLoading"
                    :height="450"
                    :data="metricDistributionData.dataset.platform"
                    :options="polarAreaChartOptions"
                />
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card no-body>
              <b-card-header>
                <b-card-title>{{ $t('dashboard.operatingSystems') }}</b-card-title>
              </b-card-header>
              <b-card-body>
                <chartjs-component-polar-area-chart-wrapper
                    v-if="!this.metricOperatingSystemsLoading"
                    :height="700"
                    :data="metricDistributionData.dataset.operatingSystem"
                    :options="polarAreaChartOptions"
                />
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card no-body>
              <b-card-header>
                <b-card-title>{{ $t('dashboard.referers') }}</b-card-title>
              </b-card-header>
              <b-card-body>
                <chartjs-component-polar-area-chart-wrapper
                    v-if="!this.metricReferersLoading"
                    :height="700"
                    :data="metricDistributionData.dataset.referer"
                    :options="polarAreaChartOptions"
                />
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import ChartjsComponentLineChartWrapper from '@/views/pages/_components/_chart/ChartjsComponentLineChartWrapper.vue'
import flatPickr from 'vue-flatpickr-component'

import {$themeColors} from '@themeConfig'
import vSelect from "vue-select";

import ChartjsComponentPolarAreaChartWrapper
  from "@/views/pages/_components/_chart/ChartjsComponentPolarAreaChartWrapper.vue";

// colors
const chartColors = {
  primaryColorShade: '#8B76EA',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    BOverlay,
    vSelect,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    ChartjsComponentLineChartWrapper,
    ChartjsComponentPolarAreaChartWrapper,
    flatPickr,
  },
  data() {
    return {
      loading: true,
      metricPageViewsLoading: false,
      metricSearchesLoading: false,
      metricOperatingSystemsLoading: false,
      metricAgentsLoading: false,
      metricPlatformsLoading: false,
      metricReferersLoading: false,
      rangePicker: [],
      datasetSkeleton: {
        label: '',
        data: [],
        lineTension: 0,
        backgroundColor: chartColors.blueColor,
        pointStyle: 'circle',
        borderColor: 'transparent',
        pointRadius: 0.5,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 5,
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: chartColors.blueColor,
        pointHoverBorderColor: $themeColors.light,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'top',
          align: 'start',
          display: false,
          labels: {
            usePointStyle: true,
            padding: 15,
            boxWidth: 8,
          },
        },
        tooltips: {
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                color: 'transparent',
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: 'transparent',
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 10,
                min: 0,
                max: 100,
                fontColor: chartColors.labelColor,
              },
              scaleLabel: {
                display: true,
              },
            },
          ],
        },
      },
      polarAreaChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          position: 'right',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 10,
            fontColor: '#6e6b7b',
          },
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scale: {
          scaleShowLine: true,
          scaleLineWidth: 1,
          ticks: {
            display: false,
            fontColor: '#6e6b7b',
          },
          reverse: false,
          gridLines: {
            display: false,
          },
        },
        animation: {
          animateRotate: false,
        },
      },
      resolutionTime: {label: '60 perc', value: 60},
      resolutionOptions: [
        {label: '5 perc', value: 5},
        {label: '15 perc', value: 15},
        {label: '30 perc', value: 30},
        {label: '60 perc', value: 60}
      ],
      pageViews: {
        labels: [],
        datasets: [],
        max: 10,
        stepSize: 1,
        summarization: [],
        options: [],
        rangePicker: [],
        resolutionTime: {label: '60 perc', value: 60},
      },
      searches: {
        labels: [],
        datasets: [],
        max: 10,
        stepSize: 1,
        summarization: [],
        options: [],
        rangePicker: [],
        resolutionTime: {label: '60 perc', value: 60},
      },
      metricDistributionData: {
        dataset: {
          operatingSystem: {
            labels: [],
            datasets: [
              {
                label: 'Operációs rendszer',
                backgroundColor: [],
                data: [],
                borderWidth: 0,
              },
            ],
          },
          agent: {
            labels: [],
            datasets: [
              {
                label: 'Böngésző',
                backgroundColor: [],
                data: [],
                borderWidth: 0,
              },
            ],
          },
          platform: {
            labels: [],
            datasets: [
              {
                label: 'Platform',
                backgroundColor: [],
                data: [],
                borderWidth: 0,
              },
            ],
          },
          referer: {
            labels: [],
            datasets: [
              {
                label: 'Referer',
                backgroundColor: [],
                data: [],
                borderWidth: 0,
              },
            ],
          }
        },
        rangePicker: [],
        resolutionTime: {label: '15 perc', value: 15},
      },
    }
  },
  created() {
    // set range picker
    const defaultDPStart = this.$helpers.getCurrentDatePlusXDays(-3)
    const defaultDPTo = this.$helpers.getCurrentDate()

    this.pageViews.rangePicker.push(defaultDPStart)
    this.pageViews.rangePicker.push(defaultDPTo)

    this.searches.rangePicker.push(defaultDPStart)
    this.searches.rangePicker.push(defaultDPTo)

    this.metricDistributionData.rangePicker.push(defaultDPStart)
    this.metricDistributionData.rangePicker.push(defaultDPTo)

    this.initPageViews()
    this.initSearches()
    this.initMetricDistributions()
  },
  methods: {
    initPageViews() {
      this.metricPageViewsLoading = true
      this.pageViews.datasets = []
      this.pageViews.summarization = []

      const rangePickerValue = this.$helpers.getVueFlatpickrValues(this.pageViews.rangePicker, this.$helpers.getCurrentDatePlusXDays(-3), this.$helpers.getCurrentDate())

      const params = {
        from: rangePickerValue[0],
        to: rangePickerValue[1],
        frequency: this.pageViews.resolutionTime.value,
      }

      this.$store.dispatch('pageViews', params).then(response => {
        this.pageViews.labels = response.data.labels

        if (response.data.dataSets.length) {
          const rDataSets = response.data.dataSets

          Object.keys(rDataSets).forEach(key => {
            let color = chartColors.primaryColorShade

            this.pageViews.datasets.push({
              ...this.datasetSkeleton,
              ...rDataSets[key],
              ...{
                backgroundColor: color,
                pointHoverBackgroundColor: color,
              },
            })
          })

          this.pageViews.max = response.data.max
          this.pageViews.stepSize = response.data.stepSize
          this.pageViews.summarization = response.data.summarization
          this.pageViews.options = this.options

          this.pageViews.options.scales.yAxes[0].ticks.min = 0
          this.pageViews.options.scales.yAxes[0].ticks.max = response.data.max
          this.pageViews.options.scales.yAxes[0].ticks.stepSize = response.data.stepSize
        }
      }).finally(() => {
        this.metricPageViewsLoading = false
      })
    },
    initSearches() {
      this.metricSearchesLoading = true
      this.searches.datasets = []
      this.searches.summarization = []

      const rangePickerValue = this.$helpers.getVueFlatpickrValues(this.searches.rangePicker, this.$helpers.getCurrentDatePlusXDays(-3), this.$helpers.getCurrentDate())

      const params = {
        from: rangePickerValue[0],
        to: rangePickerValue[1],
        frequency: this.searches.resolutionTime.value,
      }

      this.$store.dispatch('searches', params).then(response => {
        this.searches.labels = response.data.labels

        if (response.data.dataSets.length) {
          const rDataSets = response.data.dataSets

          Object.keys(rDataSets).forEach(key => {
            let color = chartColors.primaryColorShade

            this.searches.datasets.push({
              ...this.datasetSkeleton,
              ...rDataSets[key],
              ...{
                backgroundColor: color,
                pointHoverBackgroundColor: color,
              },
            })
          })

          this.searches.max = response.data.max
          this.searches.stepSize = response.data.stepSize
          this.searches.summarization = response.data.summarization
          this.searches.options = this.options

          this.searches.options.scales.yAxes[0].ticks.min = 0
          this.searches.options.scales.yAxes[0].ticks.max = response.data.max
          this.searches.options.scales.yAxes[0].ticks.stepSize = response.data.stepSize
        }
      }).finally(() => {
        this.metricSearchesLoading = false
      })
    },
    initMetricDistributions() {
      this.getMetricOperatingSystem()
      this.getMetricAgent()
      this.getMetricPlatform()
      this.getMetricReferer()
    },
    getMetricOperatingSystem() {
      this.metricOperatingSystemsLoading = true

      this.metricDistributionData.dataset.operatingSystem.labels = []
      this.metricDistributionData.dataset.operatingSystem.datasets[0].data = []
      this.metricDistributionData.dataset.operatingSystem.datasets[0].backgroundColor = []

      const rangePickerValue = this.$helpers.getVueFlatpickrValues(this.metricDistributionData.rangePicker, this.$helpers.getCurrentDatePlusXDays(-3), this.$helpers.getCurrentDate())

      const params = {
        from: rangePickerValue[0],
        to: rangePickerValue[1],
      }

      this.$store.dispatch('operatingSystems', params).then(response => {
        this.metricDistributionData.dataset.operatingSystem.labels = response.data.labels
        this.metricDistributionData.dataset.operatingSystem.datasets[0].data = response.data.dataSets
        this.metricDistributionData.dataset.operatingSystem.datasets[0].backgroundColor = response.data.colors
      }).finally(() => {
        this.metricOperatingSystemsLoading = false
      })
    },
    getMetricAgent() {
      this.metricAgentsLoading = true

      this.metricDistributionData.dataset.agent.labels = []
      this.metricDistributionData.dataset.agent.datasets[0].data = []
      this.metricDistributionData.dataset.agent.datasets[0].backgroundColor = []

      const rangePickerValue = this.$helpers.getVueFlatpickrValues(this.metricDistributionData.rangePicker, this.$helpers.getCurrentDatePlusXDays(-3), this.$helpers.getCurrentDate())

      const params = {
        from: rangePickerValue[0],
        to: rangePickerValue[1],
      }

      this.$store.dispatch('agents', params).then(response => {
        this.metricDistributionData.dataset.agent.labels = response.data.labels
        this.metricDistributionData.dataset.agent.datasets[0].data = response.data.dataSets
        this.metricDistributionData.dataset.agent.datasets[0].backgroundColor = response.data.colors
      }).finally(() => {
        this.metricAgentsLoading = false
      })

    },
    getMetricPlatform() {
      this.metricPlatformsLoading = true

      this.metricDistributionData.dataset.platform.labels = []
      this.metricDistributionData.dataset.platform.datasets[0].data = []
      this.metricDistributionData.dataset.platform.datasets[0].backgroundColor = []

      const rangePickerValue = this.$helpers.getVueFlatpickrValues(this.metricDistributionData.rangePicker, this.$helpers.getCurrentDatePlusXDays(-3), this.$helpers.getCurrentDate())

      const params = {
        from: rangePickerValue[0],
        to: rangePickerValue[1],
      }

      this.$store.dispatch('platforms', params).then(response => {
        this.metricDistributionData.dataset.platform.labels = response.data.labels
        this.metricDistributionData.dataset.platform.datasets[0].data = response.data.dataSets
        this.metricDistributionData.dataset.platform.datasets[0].backgroundColor = response.data.colors
      }).finally(() => {
        this.metricPlatformsLoading = false
      })
    },
    getMetricReferer() {
      this.metricReferersLoading = true

      this.metricDistributionData.dataset.referer.labels = []
      this.metricDistributionData.dataset.referer.datasets[0].data = []
      this.metricDistributionData.dataset.referer.datasets[0].backgroundColor = []

      const rangePickerValue = this.$helpers.getVueFlatpickrValues(this.metricDistributionData.rangePicker, this.$helpers.getCurrentDatePlusXDays(-3), this.$helpers.getCurrentDate())

      const params = {
        from: rangePickerValue[0],
        to: rangePickerValue[1],
      }

      this.$store.dispatch('referers', params).then(response => {
        this.metricDistributionData.dataset.referer.labels = response.data.labels
        this.metricDistributionData.dataset.referer.datasets[0].data = response.data.dataSets
        this.metricDistributionData.dataset.referer.datasets[0].backgroundColor = response.data.colors
      }).finally(() => {
        this.metricReferersLoading = false
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
