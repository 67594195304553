<template>
  <div>
    <b-row v-if="newComments > 0 || deleteRequests > 0">
      <b-col md="12">
        <b-alert
            variant="warning"
            class="mb-2"
            :show="true"
        >
          <div class="alert-body">
            <feather-icon
                icon="AlertTriangleIcon"
                class="mr-50"
            />
            Jelenleg {{ newComments }} db új hozzászólás és {{ deleteRequests }} db
            hozzászólás törlési kérelem vár admin moderálásra. <br>
            <b-button
                class="mt-2"
                size="sm"
                variant="warning"
                @click.stop="$router.push({ name: 'phoneNumberComments'})"
            >Tovább
            </b-button>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-overlay
          :show="basicStatisticsLoading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <div class="card card-statistics">
          <div class="card-header">
            <h4 class="card-title">{{ $t('dashboard.basicStatistics') }}</h4>
          </div>
          <div class="card-body statistics-body">
            <div class="row">
              <div class="col-12 col-sm-4">
                <div class="d-flex flex-row">
                  <div class="avatar bg-light-primary mr-2">
                    <div class="avatar-content">
                      <mdicon :name="'FlagOutline' || 'CheckboxBlankCircleOutline'"/>
                    </div>
                  </div>
                  <div class="my-auto">
                    <h4 class="fw-bolder mb-0">{{ countries }}</h4>
                    <p class="card-text font-small-3 mb-0">{{ $t('dashboard.countries') }}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <div class="d-flex flex-row">
                  <div class="avatar bg-light-info mr-2">
                    <div class="avatar-content">
                      <mdicon :name="'MapOutline' || 'CheckboxBlankCircleOutline'"/>
                    </div>
                  </div>
                  <div class="my-auto">
                    <h4 class="fw-bolder mb-0">{{ counties }}</h4>
                    <p class="card-text font-small-3 mb-0">{{ $t('dashboard.counties') }}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <div class="d-flex flex-row">
                  <div class="avatar bg-light-danger mr-2">
                    <div class="avatar-content">
                      <mdicon :name="'PhoneDialOutline' || 'CheckboxBlankCircleOutline'"/>
                    </div>
                  </div>
                  <div class="my-auto">
                    <h4 class="fw-bolder mb-0">{{ areaCodes }}</h4>
                    <p class="card-text font-small-3 mb-0">{{ $t('dashboard.areaCodes') }}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-2">
                <div class="d-flex flex-row">
                  <div class="avatar bg-light-success mr-2">
                    <div class="avatar-content">
                      <mdicon :name="'PhoneLogOutline' || 'CheckboxBlankCircleOutline'"/>
                    </div>
                  </div>
                  <div class="my-auto">
                    <h4 class="fw-bolder mb-0">{{ phoneNumbers }}</h4>
                    <p class="card-text font-small-3 mb-0">{{ $t('dashboard.phoneNumbers') }}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-2">
                <div class="d-flex flex-row">
                  <div class="avatar bg-light-warning mr-2">
                    <div class="avatar-content">
                      <mdicon :name="'CommentTextOutline' || 'CheckboxBlankCircleOutline'"/>
                    </div>
                  </div>
                  <div class="my-auto">
                    <h4 class="fw-bolder mb-0">{{ comments }}</h4>
                    <p class="card-text font-small-3 mb-0">{{ $t('dashboard.comments') }}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-2">
                <div class="d-flex flex-row">
                  <div class="avatar bg-light-success mr-2">
                    <div class="avatar-content">
                      <mdicon :name="'Magnify' || 'CheckboxBlankCircleOutline'"/>
                    </div>
                  </div>
                  <div class="my-auto">
                    <h4 class="fw-bolder mb-0">{{ dailySearches.toFixed(2) }}</h4>
                    <p class="card-text font-small-3 mb-0">{{ $t('dashboard.dailySearches') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-card>
    <metrics/>
  </div>
</template>

<script>
import {BCard, BCardText, BCol, BLink, BOverlay, BRow, BAlert, BButton} from 'bootstrap-vue'
import Metrics from "@/views/pages/_components/dashboard/statistics/Metrics.vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAlert,
    BButton,
    Metrics,
  },
  data() {
    return {
      countries: 0,
      counties: 0,
      areaCodes: 0,
      phoneNumbers: 0,
      comments: 0,
      dailySearches: 0,
      basicStatisticsLoading: false,
      commentsLoading: false,
      newComments: 0,
      deleteRequests: 0,
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.initDashboard()
    },
    initDashboard() {
      this.basicStatisticsLoading = true
      this.$store.dispatch('basicStatistics').then(response => {
        this.countries = response.data.countries
        this.counties = response.data.counties
        this.areaCodes = response.data.areaCodes
        this.phoneNumbers = response.data.phoneNumbers
        this.comments = response.data.comments
        this.dailySearches = response.data.dailySearches
      }).finally(() => {
        this.basicStatisticsLoading = false
      })

      this.commentsLoading = true
      this.$store.dispatch('waitingComments').then(response => {
        this.newComments = response.data.newComments
        this.deleteRequests = response.data.deleteRequests
      }).finally(() => {
        this.commentsLoading = false
      })
    }
  }
}
</script>
